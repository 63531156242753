import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

function OldStaffForm() {
  const [formData, setFormData] = useState({
    full_name: '',
    house_address: '',
    state_of_origin: '',
    academic_qualification: '',
    area_of_study: '',
    years_with_school: '',
    current_classes: '',
    current_subjects: '',
    continue_with_school: false,
    birth_date: '',
    professional_certificate: '',
    terms_agreed: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.terms_agreed) {
      alert('You must agree to the terms and conditions.');
      return;
    }
    const response = await fetch('https://srateachearsapplication.welearn.com.ng/server/register_old_staff.php', {
      method: 'POST',
      body: new URLSearchParams(formData),
    });
    const result = await response.text();
    alert(result);
  };

  return (
    <Container>
      <h2 className="my-4">Old Staff Re-Registration</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="formFullName">
          <Form.Label column sm={4}>Full Name:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHouseAddress">
          <Form.Label column sm={4}>House Address:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="house_address"
              value={formData.house_address}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formStateOfOrigin">
          <Form.Label column sm={4}>State of Origin:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="state_of_origin"
              value={formData.state_of_origin}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formAcademicQualification">
          <Form.Label column sm={4}>Academic Qualification/Area of Study:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="academic_qualification"
              value={formData.academic_qualification}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formYearsWithSchool">
          <Form.Label column sm={4}>Years with School:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="years_with_school"
              value={formData.years_with_school}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formCurrentClasses">
          <Form.Label column sm={4}>Current Classes Taught:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="current_classes"
              value={formData.current_classes}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formCurrentSubjects">
          <Form.Label column sm={4}>Current Subjects Taught:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="current_subjects"
              value={formData.current_subjects}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formContinueWithSchool">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="continue_with_school"
              label="Continue with school this term?"
              checked={formData.continue_with_school}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formBirthDate">
          <Form.Label column sm={4}>Birth Date:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="date"
              name="birth_date"
              value={formData.birth_date}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formProfessionalCertificate">
          <Form.Label column sm={4}>Professional Certificate (if any):</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="professional_certificate"
              value={formData.professional_certificate}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTermsAgreed">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="terms_agreed"
              label="I have read and agree to the terms and conditions."
              checked={formData.terms_agreed}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">Submit</Button>
      </Form>
    </Container>
  );
}

export default OldStaffForm;
