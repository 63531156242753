import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

function NewStaffForm() {
  const [formData, setFormData] = useState({
    full_name: '',
    will_stay_one_year: false,
    graduated: false,
    qualification: '',
    area_of_study: '',
    institution_attended: '',
    done_with_nysc: false,
    teaching_experience_years: '',
    house_address: '',
    marital_status: '',
    classes_subjects_to_teach: '',
    phone_number: '',
    email_address: '',
    terms_agreed: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.terms_agreed) {
      alert('You must agree to the terms and conditions.');
      return;
    }
  
    // Prepare data for submission
    const formDataToSubmit = {
      ...formData,
      done_with_nysc: formData.done_with_nysc ? 'on' : 'off',
      will_stay_one_year: formData.will_stay_one_year ? 'on' : 'off',
    };
  
    try {
      const response = await fetch('https://srateachearsapplication.welearn.com.ng/server/register_intending_staff.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formDataToSubmit).toString(),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.text();
      alert(result);
    } catch (error) {
      console.error('Failed to submit form:', error);
    }
  };

  return (
    <Container>
      <h2 className="my-4">New Staff Registration</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="formFullName">
          <Form.Label column sm={4}>Full Name:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formWillStayOneYear">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="will_stay_one_year"
              label="Will you stay for a period of one academic year?"
              checked={formData.will_stay_one_year}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGraduated">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="graduated"
              label="Graduated?"
              checked={formData.graduated}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formQualification">
          <Form.Label column sm={4}>Qualification/Area of Study:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formInstitutionAttended">
          <Form.Label column sm={4}>Higher Institution Attended:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="institution_attended"
              value={formData.institution_attended}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formDoneWithNysc">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="done_with_nysc"
              label="Done with NYSC?"
              checked={formData.done_with_nysc}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTeachingExperienceYears">
          <Form.Label column sm={4}>Years of Teaching Experience:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              name="teaching_experience_years"
              value={formData.teaching_experience_years}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHouseAddress">
          <Form.Label column sm={4}>House Address:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="house_address"
              value={formData.house_address}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formMaritalStatus">
          <Form.Label column sm={4}>Marital Status:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="marital_status"
              value={formData.marital_status}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formClassesSubjectsToTeach">
          <Form.Label column sm={4}>Classes/Subjects you like to teach:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="classes_subjects_to_teach"
              value={formData.classes_subjects_to_teach}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPhoneNumber">
          <Form.Label column sm={4}>Phone Number:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="tel"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formEmailAddress">
          <Form.Label column sm={4}>Email Address:</Form.Label>
          <Col sm={8}>
            <Form.Control
              type="email"
              name="email_address"
              value={formData.email_address}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTermsAgreed">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              name="terms_agreed"
              label="I have read and agree to the terms and conditions."
              checked={formData.terms_agreed}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">Submit</Button>
      </Form>
    </Container>
  );
}

export default NewStaffForm;
