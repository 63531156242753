import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import OldStaffForm from './components/OldStaffForm';
import NewStaffForm from './components/NewStaffForm';
import AdminLogin from './components/AdminLogin';
import TeacherList from './components/TeacherList';
import Policy from './components/Policy'; // Assuming you want to add this too

function App() {
  const [adminLogin, setAdminLogin] = useState(false)
  return (
    <Router>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand >SRA Staff</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/old-staff-registration">Old Staff Registration</Nav.Link>
              <Nav.Link as={Link} to="/new-staff-registration">New Staff Registration</Nav.Link>
              {
                !adminLogin ? <Nav.Link as={Link} to="/admin-login">Admin Login</Nav.Link> : <Nav.Link as={Link} to="/admin-dashboard">Admin Dashboard</Nav.Link>
              }
              <Nav.Link as={Link} to="/">Policy</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="my-4">
        <Routes>
          <Route path="/old-staff-registration" element={<OldStaffForm />} />
          <Route path="/new-staff-registration" element={<NewStaffForm />} />
          {
            adminLogin ? <Route path="/admin-dashboard" element={<TeacherList />} /> : <Route path="/admin-login" element={<AdminLogin setAdminLogin={setAdminLogin} />} />
          }
          <Route path="/" element={<Policy />} />
          <Route path="*" element={<Policy />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
