import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function AdminLogin({setAdminLogin}) {
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://srateachearsapplication.welearn.com.ng/server/admin_login.php', {
      method: 'POST',
      body: new URLSearchParams(credentials),
    });
    const result = await response.text();
    if (result==="true") {
      setAdminLogin(true)
      navigate('/admin-dashboard')
    } else {
      alert("invalid username or password")
    }
  };

  return (
    <Container className="my-4">
      <h2 className="mb-4">Admin Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="formUsername">
          <Form.Label column sm={3}>Username:</Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              placeholder="Enter your username"
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPassword">
          <Form.Label column sm={3}>Password:</Form.Label>
          <Col sm={9}>
            <Form.Control
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
            />
          </Col>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">Login</Button>
      </Form>
    </Container>
  );
}

export default AdminLogin;
