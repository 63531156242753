import React from 'react';
import { Container, Card } from 'react-bootstrap'; 
import Logo  from "../logo.jpg"

function Policy() {
  return (
    <Container className="my-4">
      <Card>
        <Card.Body style={{ textAlign: "justify" }}>
          <div style={{ width: "160px", height: "160px", overflow: "hidden", margin: "8px auto" }}>
            <img src={Logo} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt='' />
          </div>
          <Card.Title style={{ textAlign: "center" }}>Welcome to Success Royal Academy Teaching Community</Card.Title>
          <Card.Text style={{ fontWeight: "bolder" }}>
            Introduction
          </Card.Text>
          <Card.Text>
            Change according to Heraclitus, a Greek Philosopher, is the only constant thing. In Success Royal academy, we identify with Heraclitus’ dictum of change to mean constant growth, adaptability, flexibility or growth mind-set. A top ranking skill according to recent research desired among workers in the global work force. Should you join our team with a fixed mind-set, you then need to upscale fast to a growth mind-set, lest you can't thrive. Two key qualities to thrive in our team: Excellent skill in your niche and Growth mind-set or adaptability which is a form of intelligence in the global world of work.
          </Card.Text>
          <Card.Text>
            As a school, our massive vision is to be an avant-garde in creating an innovative and evolving learning community to meet the need or even outpace the real world's needs. Hence teaching team who wish to now join us or who have been with us, must have this engrained their mind: that things will keep changing and evolving as the school’ vision materializes every day. Hence we are to keep up with and embrace the pace of transformation in all aspect of the school, embracing our philosophy that the school’ interests comes first before our personal interests. Also our staff welfare is not negotiable as we will keep striving to create the most challenging, rewarding and inspiring teaching work environment and packages for our teaching force in Edo state.
          </Card.Text>
          <Card.Text>
            From the backdrop, below are our staff terms and conditions that intending staff or old staff are to carefully get abreast with before application will can be considered or old staff retained.
          </Card.Text>
          <Card.Text style={{ fontWeight: "bolder", textAlign: "center" }}>
            Our Contract: Terms and conditions.
          </Card.Text>
          <ol>
            <li>
              Must be a graduate with NYSC discharge certificate evidence for Bsc. Have to work with the school for at least one academic session before consideration to resign 
            </li>
            <li>
              All teachers must be full time, to report in school from Mondays to Fridays without one day exemption. Part time teachers are not accepted. Moreso, old staff who are not willing to switch to full time capacity may have to take jobs else where
            </li>
            <li>
              Secondary teachers for now are to teach at least 2 or 3 subjects across JSS 1-3 and SSS1-3 in line with area of specialty or related subjects. The school management is responsible to assign subjects to teach based on the school key needs and not teachers’ own personal choice.
            </li>
            <li>
              Must teach with teaching aids for each lesson taught. (Training on 3 different types of teaching aid comes up before resumption
            </li>
            <li>
              Must have a lesson plan and lesson notes prepared and seen by your line manager before teaching. Completed lesson notes which are marked by the school admin are requirement to get paid monthly
            </li>
            <li>
              Must undergo teachers’ professional refreshers course training before beginnings of new term and as well take personal development evaluation exams based on the training received online or physical.
            </li>
            <li>
              Staff are to prepare their mind for unexpected assignment of new roles and responsibilities  apart from their primary teaching jobs based on the emerging situation of the school
            </li>
            <li>
              Must ensure class environmental intelligence by allowing enough ventilation get to the class room, ensure the white marker board is always kept white, use a meter rule or improvised rule to draw lines or margins on the board, must ensure class appliances are off, windows locked, fans neat, louvres neat and class locked before leaving the school
            </li>
            <li>
              For the prenursery, nursery and primary teachers, must exhaust students’ subjects text books page by page, without skipping pages. Salary won’t be paid first month of resumption until a teacher has completed one-third of each subject text book. Then payment won't be made second month until another one-third completed and in the third month until all text book pages used up
            </li>
            <li>
              Teachers must practice take home assignment with students first and be so sure they understand it, before giving it as homework. 
            </li>
            <li>
              Teachers are to focus on their key teaching job per time and always sign their post class record for secondary teachers. This is another requirement to get monthly payment. Not signing means you didn’t teach for that day. Moreso, each teacher must be in the classroom teaching as stated on the timetable. Copying of notebook on the board without teaching or explanation or giving students note to copy while you are in class or away attract immediate sack.
            </li>
            <li>
              Any staff employed in the secondary arm is a possible form teacher. You will be assigned to a class to oversee their general welfare and wellbeing. Duties like marking of class register, noticing and visiting absent students that same day of observation, ensuring the class is groomed academically, noting the class needs and challenges to the management, ensuring the class examination results and report sheets are ready and error free at the end of the term. Each form teacher’s office will now be in their assigned classroom to help as standby mentor and monitor of our students.
            </li>
            <li>
              Newly employed staff will be on probation for 2weeks, and if they don't satisfy our work terms and culture, they will be stepped down immediately, while old staff who violates any of our work standards will be issued a query and fine, and upon repetition will be asked to resign without any apologies.
            </li>
            <li>
              Each staff will be under collaborative inspection and appraisal program with constructive feedback on how they are doing on their job. This is to keep guiding them to adopt the school unique teaching method and plan to realize our set objectives of learning by students. Moreso, a monthly performance appraisal on each staff will be published on the notice board on all aspects of a teacher's work in the school. As we are creating a work culture that will constantly challenge us and bring out our best 
            </li>
            <li>
              Punctuality is not negotiable (7:30am), absenteeism is not negotiable, absconding from duty is not negotiable, and not clocking in when you resume work is not negotiable. Also as a teacher on duty roaster for the week, you must keep to the terms, be the first to be in school by 7:15am and last to leave, complete and sign your report before leaving. Absent from work without proper written permission is automatic withdrawal from service. Absent from work with due permission will also attract a fine (#500) for not being present that day. Late coming fine is #100 with permission and #200 without permission
            </li>
            <li>
              Some attitude that you must not bring to our work environment: Gossip, back biting (if you can't say in the presence of a staff what you said behind, please keep it to yourself), sleeping in class, or staffroom, no use of phone during class hours, must not teach with text books in class, no use of earpiece, air pods as a class teacher, no quarrelling, no lazy attitude to work, no use of cane or any objects like wood, rods, wires to flog students. Cain may be used in some cases sparingly but not to inflict injury. No undue punishment or hard punishment on students leaving them on punishment for a long time. Device positive and creative ways to punish each student or class that will bring about positive change in behaviour and attitude.
            </li>
            <li>
              Writing skill development session on neat and legible write ups in notebooks, notebook management, must be taught to students by each class or form teacher from the beginning of the term and continuously.
            </li>
            <li>
              Teachers must refer students who are weak academically to our new remedial and support clinic to treat such student by the professionals in charge. It’s forbidden to have any child in your class who cannot read and write fluently over a period of one term.
            </li>
            <li>
              Teachers are to give the  management at least 3 months’ notice before resignation, and also such teacher is to support the management to find a replacement if possible to hand over. Any staff who reigns immediately without prior notice to the management will have his or her salary forfeited for that month. While staff who stops work without prior notice to the school after getting salary while the term is still ongoing will be called for detention by the police force assigned to the school and made to pay back the salary before release.
            </li>
            <li>
              Staff must attend the following meeting and events. They are requirements to get your pay. As meetings are key part of your mandate in the school. Weekly or monthly Staff and management meetings with directors( physical or virtual), daily, weekly or monthly cooperate staff prayers and that with the students, sports activities( All teachers must be outside to coordinate), graduation planning meeting, pre-graduation preparation or any activities in the school. Etc. Any meeting missed attract a fine of #1000 with a query and then such staff must get a copy of the minute of meeting immediately.
            </li>
            <li>
              Class teachers in collaboration with our ICT team are in charge of teaching their classes for the compulsory ICT training for students (kg II-SSS 3) Teachers are also to use the platform to learn on the job and support the ICT manager to teach and coordinate the session. Teachers are to take the ICT evaluation test with their students at the end of the term.
            </li>
            <li>
              No teacher should be mean to our students, you must show constant love, believe, empathy and support to help them grow and overcome any vices. Report any case that appears to be beyond your capacity to handle to the management staff for support.
            </li>
            <li>
              The school hierarchy must be respected and adhered to. Teachers are to collaborate closely with the management staff and follow their instructions. Disregarding, quarrelling, arguing or ignoring any management staff’ instructions as it relates to the school official work means immediate sack. 
            </li>
            <li>
              30mins Morning drills program in each class is a must across all classes from nursery to Secondary (To be handled by form teachers; you are to design their drill activities as you deem fit to meet your class’ academic needs)
            </li>
            <li>
              Teachers are to resume school daily motivated with the mind to do their job and really teach and impact our students. Should there be any prior conflict, seek to resolve it immediately with the party involved while you focus on mutual solution rather than blame game or abuse of personality.
            </li>
            <li>
              No student must fail any subject at the end of the term. The subject teacher will be held responsible. No use of red biro in exam sheet. From 1st test, subject teachers are to use the formative evaluation result to know how to further support poor performances or tweak their teaching methods to help weaker students. Very weak students who don't learn at the same pace as others can be evaluated orally and score recorded or other means of evaluation apart from written model of exam.
            </li>
            <li>
              Must open staff salary account and fill staff biodata form and open a staff file with the school secretary within first week of resumption of duty before they are eligible to receive any staff benefit. Any person without personal file opened with the school is not considered a staff yet.
            </li>
            <li>
              All after school lesson program are to be organized in partnership with the school and teachers. No teacher is permitted to have any private lesson using the school facility. Parents are to be directed by teachers to the school management staff for any after school lesson request. This program is to be monitored by the management staff on effectiveness.
            </li>
            <li>
              Teacher must ensure that student’s lesson note are different from school note books. Any student using school note during lesson hours will be sanctioned. Also lesson topics must be different from regular school work. We must be accountable to parents on this as they do pay for it separately
            </li>
            <li>
              Teachers must note mark or write inside the notebooks of students. Use the comment and score session provided in the Success Royal Academy customized notebooks.
            </li>
            <li>
              No teacher is to engage in any personal practical activities in the school or charge students for any activities without permission of the school director and school admin at the same time. Such teacher will be sanctioned. All teachers must follow the school admin directives on all academic activities and teaching model.
            </li>
            <li>
              No teacher must leave the school premises during work hours. Except for emergency under the permission of any Management staff and our safety officer managing the gate, as the school gate will is being locked. 
            </li>
          </ol>
          <Card.Text>
            Finally, our overall teaching outlook, attitude and philosophy is to help our students to be aware of concepts in the curriculum, relate them to local environment,  become inquisitive and use that level of knowledge to become creative to produce new knowledge. We are not teaching them as slaves, or as students who are dull, but as royalties needing our support to evolve their hidden potentials and talents.
            Welcome to Success Royal Academy teaching force, we are a dynamic and exciting teaching community poised to bring positive change and practical local innovations to the teaching industry in Benin City and West Africa 
          </Card.Text>

        </Card.Body>
      </Card>
    </Container>
  );
}

export default Policy;
