import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Button, Row, Col } from 'react-bootstrap';

function TeacherList() {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchApplications() {
      try {
        const response = await fetch('https://srateachearsapplication.welearn.com.ng/server/view_applications.php');
        if (!response.ok) {
          if (response.status === 403) {
            setError('Access denied. Please log in.');
            return;
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.error) {
          setError(data.error);
          return;
        }
        setApplications(data);
      } catch (error) {
        setError(`Failed to fetch applications: ${error.message}`);
      }
    }
    fetchApplications();
  }, []);

  const handleAction = async (id, action) => {
    try {
      const response = await fetch(`https://srateachearsapplication.welearn.com.ng/server/${action}_teacher.php`, {
        method: 'POST',
        body: new URLSearchParams({ id }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.text();
      alert(result);
      const changedStatus = applications.map((app) => {
        if (app.id === id) {
          app.status = action === "reject" ? "rejected" : "approved"
          return app
        }
        return app
      })
      setApplications(changedStatus);
    } catch (error) {
      console.error(`Failed to ${action} teacher:`, error);
    }
  };

  return (
    <Container className="my-4">
      <h2 className="mb-4">Teacher Applications</h2>
      {error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <ListGroup>
          {applications.length > 0 ? (
            applications.map(app => (
              <ListGroup.Item key={app.id}>
                <Row>
                  <Col style={{textTransform: 'capitalize'}}>
                    <div style={{margin: "8px 0px"}}>
                      <Button style={{fontWeight: "bolder", marginRight: "8px"}} variant={ app.is_old_staff === "0" ? "primary" : "secondary" } > {app.is_old_staff === "0" ? "New Staff" : "Old Staff"} </Button>
                    </div>
                    {
                      app.full_name && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Name: </span> <span>{app.full_name || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.application_date && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Application Date: </span> <span>{app.application_date || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.academic_qualification && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Academic Qualification: </span> <span>{app.academic_qualification || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.birth_date && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>DOB: </span> <span>{app.birth_date || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.done_with_nysc && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Done with NYSC: </span> <span>{app.done_with_nysc === "1" ? "Yes" : "No" || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.email_address && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Email: </span> <a  href={`mailto:${app.email_address}`}><span style={{textTransform: "lowercase"}}>{app.email_address || "None"}</span></a>
                        </div>
                      )
                    }
                    {
                      app.phone_number && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Phone: </span> <a href={`tel:${app.phone_number}`}><span>{app.phone_number || "None"}</span></a>
                        </div>
                      )
                    }
                    {
                      app.professional_certificate && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Professional Certificate: </span> <span>{app.professional_certificate || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.marital_status && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Marital status: </span> <span>{app.marital_status || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.state_of_origin && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>State of Origin: </span> <span>{app.state_of_origin || "None"}</span>
                        </div>
                      )
                    }
                    {
                      app.will_stay_one_year && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Will stay one year: </span> <span>{app.will_stay_one_year ==="1" ? "yes" : "no" || "" }</span>
                        </div>
                      )
                    }
                    
                    {
                      app.institution_attended && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Institution Attended: </span> <span>{app.institution_attended || "None"}</span>
                        </div>
                      )
                    }
                    
                    {
                      app.graduated && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Graduated: </span> <span>{app.graduated || "No"}</span>
                        </div>
                      )
                    }
                    
                    {
                      app.continue_with_school && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Continue with School: </span> <span>{(app.continue_with_school ==="1" ? "yes" : "no" ) || ""}</span>
                        </div>
                      )
                    }
                    
                    {
                      app.current_classes && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Current Classes: </span> <span>{app.current_classes || "No"}</span>
                        </div>
                      )
                    }

                    {
                      app.current_subjects && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Current Subject: </span> <span>{app.current_subjects || "No"}</span>
                        </div>
                      )
                    }

                    {
                      app.house_address && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>House Address: </span> <span>{app.house_address || "No"}</span>
                        </div>
                      )
                    }

                    {
                      app.professional_certificate && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Professional Certificate: </span> <span>{app.professional_certificate || "No"}</span>
                        </div>
                      )
                    }

                    {
                      app.teaching_experience_years && (
                        <div style={{margin: "8px 0px"}}>
                          <span style={{fontWeight: "bolder", marginRight: "8px"}}>Teaching Experience Years: </span> <span>{app.teaching_experience_years || "No"}</span>
                        </div>
                      )
                    }
                    
                  </Col>
                </Row>
                <Row className="text-end">
                  {
                    app.status === "rejected" ?
                    <Button 
                      variant="danger"
                      disabled
                    >
                      Rejected
                    </Button> :
                    app.status === "pending" ? 
                    <Row style={{textAlign: "center"}}>
                      <Col>
                        <Button 
                          variant="success" 
                          className="me-2"
                          style={{display: "block", width: "100%"}}
                          onClick={() => handleAction(app.id, 'approve')}
                        >
                          Approve
                        </Button>
                      </Col>
                      <Col>
                        <Button 
                          variant="danger" 
                          onClick={() => handleAction(app.id, 'reject')}
                          style={{display: "block", width: "100%"}}
                        >
                          Reject
                        </Button>
                      </Col>
                    </Row> :
                    <Button 
                      variant="success" 
                      className="me-2"
                      disabled
                    >
                      Approved
                    </Button>

                    
                  }
                  
                  
                </Row>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No applications found.</ListGroup.Item>
          )}
        </ListGroup>
      )}
    </Container>
  );
}

export default TeacherList;
